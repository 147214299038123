import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useMemo } from "react";
import { useParams } from "react-router";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { questionnaireService } from "../../state/questionnaire/questionnaire.service";
import { userService } from "../../state/user/user.service";
import { BoxShadowHead } from "../elements/box-shadow-head.element";
import { Flex } from "../elements/flex.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { SvgIcon } from "../elements/svg-icon.element";
import { ReactComponent as checkIcon } from "../../assets/graphics/symbols/checkmark.svg";
import { ClientQuestionnaireResultsTable } from "../templates/tables/clientprofile/client-questionnaire-results-table.template";
import { locale } from "../../constants/locale.constants";

export const QuestionnaireResultsScreen: FC = () => {
  const params = useParams<{
    questionnaireId: string;
    userId: string;
    exerciseInviteId: string;
  }>();

  const { data: questionnaire } = useById(
    questionnaireService,
    params.questionnaireId,
    {
      populate: [
        {
          path: "questions",
          sort: ["-questionIndex"],
          populate: [
            {
              path: "userAnswers",
              match: { userId: params.userId },
            },
          ],
        },
      ],
    },
  );

  const { data: user } = useById(userService, params.userId, {
    select: ["name", "lastName"],
  });

  /**
   * Returns date of completion if completed, otherwise returns null
   */
  const completionDate = useMemo(() => {
    const questions = questionnaire?.questions;
    const userAnswers = questions?.flatMap(
      (question) => question.userAnswers ?? [],
    );

    if (!userAnswers?.length || !questions?.length) {
      return null;
    }

    const userAnswerQuestionIds = userAnswers.map(
      (answer) => answer?.questionId,
    );
    const isQuestionnaireCompleted = questions?.every((question) =>
      userAnswerQuestionIds?.includes(question._id),
    );

    // Use the createdAt of the userAnswer to the last question
    const lastCreatedAt = userAnswers[userAnswers.length - 1].createdAt;
    if (!isQuestionnaireCompleted || !lastCreatedAt) {
      return null;
    }

    return new Date(lastCreatedAt);
  }, [questionnaire?.questions]);

  return (
    <Page>
      <PageBody fullWidth>
        <BoxShadowHead>
          <Flex direction="column" alignItems="flex-start" gap={10}>
            <Header>{questionnaire?.title}</Header>
            <Header size={"small"}>
              {user?.name} {user?.lastName}
            </Header>
            {completionDate && (
              <Flex gap={10}>
                <SvgIcon
                  icon={checkIcon}
                  color={"rgb(var(--color-black))"}
                  width={20}
                  height={20}
                />
                <Header size={"small"}>
                  {dictionary.literals.completedOn}{" "}
                  {completionDate.toLocaleDateString(locale)}
                </Header>
              </Flex>
            )}
          </Flex>
        </BoxShadowHead>
        <ClientQuestionnaireResultsTable
          clientId={params.userId}
          questionnaireId={params.questionnaireId}
          exerciseInviteId={params.exerciseInviteId}
        />
      </PageBody>
    </Page>
  );
};
